let linksHash = $('a[href^="#"]');
const siteHeader = $('.c-site-header');


let urlPage = window.location.href;
if (urlPage.split('#')[1]) {
    let idLinkPage = $('#' + $.escapeSelector(urlPage.split('#')[1]));

    $(window).on('load', function () {
        $('html, body').animate({
            scrollTop: idLinkPage.offset().top - siteHeader.outerHeight()
        });
    });
}


if (linksHash.length) {
    $(document).on('click', 'a[href^="#"] ', function (e) {
        let targetLink = $(this).attr('href');
        if (targetLink.length === 1 || targetLink === '#modal-turbo') return 
        let scrollTopTarget;
        if ($(this).hasClass('js-site-footer-go-to-top')) {
            e.preventDefault();
            scrollTopTarget = 0;
        } else {
            scrollTopTarget = $(targetLink).offset().top - siteHeader.outerHeight(); 
        }
        $('html, body').animate({
            scrollTop: scrollTopTarget
        }, function () {
            if ($(targetLink).is('select, input, textarea, button, a')) {
                $(targetLink).focus();
            } else {
                $(targetLink).css('outline-offset', '-2px').attr('tabindex', -1).focus();
            }
        });
    });
}